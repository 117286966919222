import { RouteObject } from 'react-router';

import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'src/layouts/BaseLayout';
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';


import dashboardsRoutes from './dashboards';
import documentsRoutes from './documents';
import accountsRoutes from './accounts';
import ticketsRoutes from './tickets';
import rewardsRoutes from './rewards'
import ibDetailsRoutes from './ibDetails'
import championContestRoutes from './championContest'
import moneyMovementRoutes from './moneyMovement'
import analysisRoutes from './analytics'


import tradeTerminalRoutes from './tradeTerminal';
import socialTradingRoutes from './socialTrading';
import accountRoutes from './account';
import baseRoutes from './base';

const router: RouteObject[] = [
  {
    path: '',
    children: accountRoutes
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  },
  // Bottom Navigation Layout

  { 
    path: 'dashboard',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="home" replace />
      },
      {
        path: 'home',
        children: dashboardsRoutes
      },
      {
        path: 'documents',
        children: documentsRoutes
      },
      {
        path: 'accounts',
        children: accountsRoutes
      },
      {
        path: 'tradeTerminal',
        children: tradeTerminalRoutes
      },
      {
        path: 'socialTrading',
        children: socialTradingRoutes
      },
      {
        path: 'rewards',
        children: rewardsRoutes
      },
      {
        path: 'ib_details',
        children: ibDetailsRoutes
      },
      {
        path: 'championContest',
        children: championContestRoutes
      },
      {
        path:'money',
        children: moneyMovementRoutes
      },  {
        path:'analysis',
        children: analysisRoutes
      },
      {
        path: 'tickets',
        children: ticketsRoutes
      },
 
      
    ]
  },
 
];

export default router;

import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import { AccountBalanceTwoTone, AddCardTwoTone, BarChartTwoTone, CardGiftcardTwoTone, CompareArrowsTwoTone, CurrencyExchangeTwoTone, DashboardTwoTone, DownloadForOfflineTwoTone, EmojiEventsTwoTone, 
  Inventory2TwoTone,
  PersonTwoTone,
  IosShareTwoTone, ShowChartTwoTone, SupportAgentTwoTone, SaveAltTwoTone, UploadTwoTone } from '@mui/icons-material';

  export interface MenuItem {
    link?: string;
    icon?: ReactNode;
    badge?: string;
  
    items?: MenuItem[];
    name: string;
    external?: boolean;
  }

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        icon: DashboardTwoTone,
        // badge: 'v3',
        link: '/dashboard/home',
       
      },
      {
        name: 'Documents',
        icon: BackupTableTwoToneIcon,
        // badge: 'v3',
        link: '/dashboard/documents',
       
      },
      {
        name: 'Trading Accounts',
        icon: AccountBalanceTwoTone,
        // badge: 'v3',
        link: '/dashboard/accounts',
       
      },
      
      // {
      //   name: 'Rewards Trading',
      //   icon: CardGiftcardTwoTone,
      //   link: '/dashboard/rewards',
      //   items: [
      //     {
      //       name: 'Rewards',
      //       link: '/dashboard/rewards/rewards',
            
      //     },
      //     {
      //       name: 'IB Rewards',
      //       link: '/dashboard/rewards/ibRewards',
            
      //     },
      //   ]
      // },
    
    
      {
        name: 'Champion Contest',
        icon: EmojiEventsTwoTone,
        link :'/dashboard/championContest'
      },
     
      {
        name: 'Manage Funds ',
        icon: IosShareTwoTone,
        link: '/dashboard/money/',
        items: [
          {
            name: 'Deposits',
            icon: SaveAltTwoTone,
            link :'/dashboard/money/deposit'
          },
          {
            name: 'Withdrawal',
            icon: UploadTwoTone,
            link :'/dashboard/money/withdraw'
          },
          {
            name: 'Internal Transfer',
            link: '/dashboard/money/transfer-internal',
            
          },
          {
            name: 'External Transfer',
            link: '/dashboard/money/transfer-external',
            
          },
        ]
      },
      {
        name: 'Web Trading',
        icon: ShowChartTwoTone,
        link :'/dashboard/tradeTerminal/tradeTerminal'
      },
      {
        name: 'IB Details',
        icon: PersonTwoTone,
        link :'/dashboard/ib_details'
      },
     
      {
        name: 'Social Trading',
        icon: CompareArrowsTwoTone,
        link: '/dashboard/socialTrading',
        items: [
          {
            name: 'Social Trading',
            link: '/dashboard/socialTrading',
            
          },
          {
            name: 'Social Trading Provider',
            external:true,
            link: 'https://ratings.dmacapitals.com/',
            
          },
          {
            name: 'Social Trading Login',
            external:true,
            link: 'http://invest.dmacapitals.com/portal/login',
            
          },
        ]
      },
     
      // {
      //   name: 'Analysis & Tools',
      //   icon: BarChartTwoTone,
      //   link :'/dashboard/analysis'
      // },
      {
        name: 'Support Tickets',
        icon: SupportAgentTwoTone,
        // badge: 'v3',
        link: '/dashboard/tickets',
      },
      
      {
        name: 'Downloads',
        icon: DownloadForOfflineTwoTone,
        link: '',
        items: [
          {
            name: 'MT5 for Windows',
            external:true,
            link: 'https://download.mql5.com/cdn/web/19700/mt5/dmacapitals5setup.exe',
            
          },
          {
            name: 'MT5 for Android',
            external:true,
            link: 'https://download.mql5.com/cdn/mobile/mt5/android?server=DMACapitals-MT5',
            
          },
          {
            name: 'MT5 for iOS',
            external:true,
            link: 'https://download.mql5.com/cdn/mobile/mt5/ios?server=DMACapitals-MT5'
          },
          
        ]
      },
   
   
      
      
    ]
  }
];

export default menuItems;
